import { AbstractControl, ValidationErrors } from '@angular/forms';

/**
 * Since Angular's Validators.maxLength only works for strings and arrays, we can
 * go with having a validator to validate the length of a number value, and that
 * is where this validator comes into the picture.
 */
const numberInputTypeMaxLengthValidator =
  (maxLength: number) =>
  (control: AbstractControl): ValidationErrors | null =>
    control?.value?.toString().length > maxLength
      ? {
          fieldHasInvalidLength: `Field must be equal to or less than ${maxLength}`,
        }
      : null;

export default numberInputTypeMaxLengthValidator;
