<div class="signup-details-wrapper">
  <ng-container *ngIf="headerText">
    <h4 class="signup-details-wrapper__header heading2--bold">
      {{ headerText }}
    </h4>
  </ng-container>
  <ng-container *ngIf="subHeaderText">
    <p class="signup-details-wrapper__sub-header body1--regular">
      {{ subHeaderText }}
    </p>
  </ng-container>
  <form [formGroup]="signupForm" (ngSubmit)="onSubmit()">
    <div class="signup-details-container">
      <div class="signup-details-header">
        <div class="signup-details-header-section">
          <span class="signup-details-header-section__icon-container">
            <i class="icon icon-auth-profile signup-details-header-section__icon"></i>
          </span>
          <h4 class="signup-details-header__label body2--bold">معلوماتك شخصية</h4>
        </div>
      </div>

      <input
        placeholder="الاسم كامل"
        type="text"
        formControlName="fullName"
        class="body2--medium"
      />

      <mat-form-field appearance="fill" class="signup-details-dropdown">
        <mat-label class="body2--medium">الجنسية</mat-label>
        <mat-select formControlName="nationality" class="body2--medium">
          <mat-option
            *ngFor="let nationality of nationalities"
            [value]="nationality.isoCode3"
            class="body2--medium"
          >
            {{ nationality.arabicName }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="fill" class="signup-details-dropdown">
        <mat-label class="body2--medium">الاقسام اللتي تعمل بها</mat-label>
        <mat-select multiple formControlName="preferredCategories">
          <mat-option
            *ngFor="let category of categories"
            [value]="category.key"
            class="body2--medium"
            >{{ category.value.name.arabicName }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <mat-chip-list #chipList>
        <mat-chip
          *ngFor="let category of preferredCategories$ | async"
          (removed)="remove(category.key)"
        >
          <h5 class="body2--medium">
            {{ category.label }}
          </h5>
          <button matChipRemove>
            <mat-icon>
              <i class="icon icon-auth-remove mat-chip__icon"></i>
            </mat-icon>
          </button>
        </mat-chip>
      </mat-chip-list>

      <h5 class="signup-details-container__label body2--medium">الجنس</h5>

      <mat-radio-group class="signup-details-radio-group" formControlName="gender">
        <mat-radio-button
          class="signup-details-radio"
          value="female"
          [ngClass]="getActiveRadioButton('gender') === 'female' ? 'selectedChip' : ''"
        >
          <i
            class="icon icon-auth-female signup-details-radio__icon signup-details-radio__female"
          ></i>
          <h5 class="body1--medium">انثى</h5>
        </mat-radio-button>
        <mat-radio-button
          class="signup-details-radio"
          value="male"
          [ngClass]="getActiveRadioButton('gender') === 'male' ? 'selectedChip' : ''"
        >
          <i class="icon icon-auth-male signup-details-radio__icon signup-details-radio__male"></i>
          <h5 class="body1--medium">ذكر</h5>
        </mat-radio-button>
      </mat-radio-group>

      <h5 class="signup-details-container__label body2--medium">السن</h5>

      <mat-radio-group class="signup-details-radio-group" formControlName="age">
        <div class="signup-details-age-container">
          <mat-radio-button
            *ngFor="let ageGroup of ageGroups"
            class="signup-details-radio"
            [value]="ageGroup.value"
            [ngClass]="getActiveRadioButton('age') === ageGroup.value ? 'selectedChip' : ''"
          >
            <h5 class="body1--medium">
              {{ ageGroup.displayValue }}
            </h5>
          </mat-radio-button>
        </div>
      </mat-radio-group>
    </div>

    <div class="signup-details-container">
      <div class="signup-details-header">
        <div class="signup-details-header-section">
          <span class="signup-details-header-section__icon-container">
            <i class="icon icon-auth-store signup-details-header-section__icon"></i>
          </span>
          <h4 class="signup-details-header__label body2--bold">هل لديك متجر الكتروني؟</h4>
        </div>
        <mat-slide-toggle
          class="signup-details-store-toggle"
          (change)="setSliderValue($event)"
        ></mat-slide-toggle>
      </div>
      <ng-container *ngIf="getSliderValue()">
        <input
          placeholder="اسم المتجر"
          type="text"
          formControlName="storeName"
          class="body2--medium"
        />
      </ng-container>
    </div>
    <button
      class="signup-details-continue-button body1--bold"
      type="submit"
      [disabled]="!signupForm.valid"
    >
      <ng-container
        class="body2--medium"
        *ngTemplateOutlet="
          scope === 'signup' ? signupSubmitButtonTemplate : optInSubmitButtonTemplate
        "
      ></ng-container>
    </button>
  </form>
</div>

<ng-template #signupSubmitButtonTemplate> إنشاء الحساب </ng-template>

<ng-template #optInSubmitButtonTemplate> تحديث البيانات </ng-template>
