<div class="dialog-container">
  <p class="heading2--bold">
    يعد توثيق بريدك الإلكتروني أمرًا بالغ الأهمية.<br />
    إذا لم تقم بتوثيقه قريبًا، ستفقد إمكانية سحب ارباحك
  </p>
  <div class="btn-container">
    <button class="ghost" (click)="closeDialog()">الغاء</button>
    <button class="primary_brand" (click)="skipForNow()">استمرار</button>
  </div>
</div>
