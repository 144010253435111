import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CurrentUserStep } from 'src/app/presentation/shared/components/shared-stepper-indicator/interfaces';
import { BaseComponent } from '../../../shared/components/BaseComponent';

/**
 * Each of the signup stepper components will need to extend this base component,
 * because they all inherently have the same behavior - they are steps by design,
 * however are extensible to meet different needs
 */
@Component({
  template: '',
  standalone: true,
})
export class SignupStepsBaseComponent extends BaseComponent {
  /**
   * So, we will be passing in optional payload depending on the screen. Ideally, and by default,
   * since we will be working with a JWT, we will explicitly, pass in the decoded value of the JWT,
   * and this way, we make our screen unaware of what is happening outside of them.
   */
  @Input() registerStatusJWTToken: string;

  @Input() nextStep: CurrentUserStep;

  @Output() goToNextStep$: EventEmitter<CurrentUserStep> = new EventEmitter<CurrentUserStep>();

  @Output() optionalData$: EventEmitter<any> = new EventEmitter<any>();

  public authAssetsRelativePath = '/assets/img/auth/';

  public payload: any;
}
