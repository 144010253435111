import { Component, inject } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-skip-otp-dialog',
  templateUrl: './confirm-skip-otp-dialog.component.html',
  styleUrls: ['./confirm-skip-otp-dialog.component.scss'],
  standalone: true,
  imports: [MatDialogModule],
})
export class ConfirmSkipOtpDialogComponent {
  private _matdialog = inject(MatDialogRef<ConfirmSkipOtpDialogComponent>);

  public closeDialog(): void {
    this._matdialog.close();
  }

  public skipForNow(): void {
    this._matdialog.close({ skipOTP: true });
  }
}
